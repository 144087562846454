import type { VariantProps } from 'class-variance-authority'

import { Slot } from '@radix-ui/react-slot'
import { cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@conkoa/ui/utils/misc'

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-xl text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-muted bg-background hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        'destructive-outline':
          'border border-destructive text-destructive hover:bg-destructive/10',
        'secondary-outline':
          'border border-secondary text-secondary hover:bg-secondary/10',
        'primary-outline':
          'border border-primary/80 hover:border-primary hover:bg-primary/10',
        google:
          'border border-[#4285F4] bg-white text-[#4285F4] hover:bg-[#4285F4]/5 hover:text-[#2674f2] focus:bg-[#4285F4]/5 focus:text-[#2674f2] dark:bg-[#4285F4] dark:text-white',
      },
      size: {
        default: 'h-10 px-4 py-2',
        wide: 'px-24 py-5',
        sm: 'h-9 rounded-md px-3',
        xs: 'rounded-md px-2 py-1',
        lg: 'h-11 rounded-md px-8 text-base',
        pill: 'px-12 py-3 leading-3',
        icon: 'size-8 p-0',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
